body {
    margin: 0px;
    padding: 0px;
    font-family: Roboto;
}

h1, h2, h3 {
    font-weight: bold;
}

.header {
    background-color: #3E3B4E;
    margin: 0px;
    padding: 20px 0px;

}

.header ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 30px 0px 5px 0px;
    overflow: hidden;
}

.header ul li {
    width: 100%;
    margin: 0px;
    padding: 14px 5px 0px 15px;
}

.header ul li.active {
    width: 100%;
    background-color: #E8DBC3;
    padding: 14px 5px 0px 15px;
    border-right: 5px solid #F6BA42;
}

.header ul li.active .menu {
    color: #3E3B4E;
}

.header ul li.active .menu svg path {
    fill: #3E3B4E;
}

.header ul li:hover .menu {
    color: #3E3B4E;
}

.header ul li:hover {
    width: 100%;
    background-color: #E8DBC3;
    padding: 14px 5px 0px 15px;
    border-right: 5px solid #F6BA42;
}

.header ul li:hover .menu {
    color: #3E3B4E;
}

.header ul li:hover .menu svg path {
    fill: #3E3B4E;
}

.menu {
    text-decoration: none;
    color: white;
}

.content {
    margin-left: 260px;
    width: 100%;
}


.topMenu {
    list-style-type: none;
    text-align: right;
    margin: 0px;
    height: 100px;
}

.user {
     border: 2px solid #3E3B4E;
     padding: 5px;
     margin-right: 20px;
     border-radius: 50%;
}

.topMenu hr {
    padding: 0px;
    margin: 10px 0px;
}

.logo {
    width: 50px;
    margin-left: 25px;
}

.card {
    background-color: #F2F2F2;
    width: 100%;
    text-align: left;
    margin: 25px 5px 25px 25px;
    padding: 20px 20px 20px 20px;
    border: solid 1px #F6BA42;
    border-radius: 20px;
    color: #3E3B4E !important;
}

.lang {
    font-size: 14px;
}

.card p {
    color: #3E3B4E;
}

.viewMore {
    color: #F6BA42;
}
.card1 {
    background-color: #F4F4F4;
    width: 95%;
    margin: 10px 5px 25px 25px;
    padding: 10px 20px 40px 20px;
    border: solid 1px #D8D8D8;
    border-radius: 20px;
    text-align: left;
}

.card1 table,
.card2 table {
    width: 100%;
}

p {
    color: #fff;
}

.link {
    background-color: white;
    width: 100%;
    text-align: center;
    border-radius: 50px;
    border: none;
    padding: 12px;
}

.icon {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.left {
    text-align: left;
    padding: 10px 0px;
}

.left span {
    font-weight: bold;
    font-size: 16px;
}

.left label {
    margin-top: -25px;
    font-size: 14px;
}

.right {
    text-align: right;
}

.skill {
    display: inline-block;
}

.name1 {
    display: inline-block;
    background-color: #fff;
    color: black;
    padding: 5px 20px;
    font-size: 12px;
    margin: -10px 0px 30px 20px;
    border-radius: 20px;

}

.compete {
    display: inline-block;
}

.name {
    display: inline-block;
    background-color: #fff;
    color: black;
    padding: 5px 20px;
    font-size: 12px;
    margin: -10px 0px 20px 10px;
    border-radius: 20px;

}

.danger {
    padding: 10px 50px 10px 50px;
    border-radius: 100px;
    background-color: #F65042;
    border: none;
    color: white;
    font-size: 14px;
}

.success {
    padding: 10px 50px 10px 50px;
    border-radius: 100px;
    background-color: #27E34B;
    border: none;
    color: white;
    font-size: 14px;
}

.sendCode {
    padding: 15px 100px 15px 100px;
    border-radius: 100px;
    background-color: #3E3B4E;
    border: none;
    color: white;
    font-size: 16px;
}

.addskill {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: #F6BA42;
    border: none;
    color: white;
    font-size: 16px;
}

.addstatement {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: #27E34B;
    border: none;
    color: white;
    font-size: 16px;
}

.addtalent {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: #3E3B4E;
    border: none;
    color: white;
    font-size: 16px;
}

.addquestionaire {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: #F65042;
    border: none;
    color: white;
    font-size: 16px;
}

.edit {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: #27E34B;
    border: none;
    color: white;
    font-size: 12px;
}

.delete {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: rgb(238, 67, 67);
    border: none;
    color: white;
    font-size: 14px;
}

.form {
    text-align: left;
    margin: 20px 30px;
}

.form input, .form select, .form textarea, .input {
    width: 100%;
    margin: 10px 0px 20px 0px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #363636;
    border-radius: 0px;
    background-color: transparent;
}

.form input:focus, .form select:focus, .form textarea:focus, .input:focus {
    width: 100%;
    margin: 10px 0px 20px 0px;
    outline: none !important;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #363636;
    border-radius: 0px;
    background-color: transparent;
}

.form div button {
    width: 50%;
    border-radius: 50px;
    font-size: 18px;
}

.tab {
    background-color: #F4F4F4;
    color: #3E3B4E;
    font-size: 20px;
    padding: 1vw 4vw;
    border: none;
    border-bottom: 3px solid #3E3B4E;
}

.tab:hover {
    background-color: #3E3B4E;
    color: #F4F4F4;
    font-size: 20px;
    padding: 1vw 4vw;
    border: none;
    border-bottom: 3px solid #3E3B4E;
}

.tabActive {
    background-color: #3E3B4E;
    color: #F4F4F4;
    font-size: 20px;
    padding: 1vw 4vw;
    border: none;
    border-bottom: 3px solid #3E3B4E;
}

.footer {
    margin-top: 220px;
    padding: 0px;
}
