.subtitle {
    color: #363636;
}

.cardGame {
    padding: 20px;
    text-align: left;
    user-select: none;
    margin-bottom: 15px;
    border-radius: 20px;
    background-color: #faf7f7;
    color: #3E3B4E !important;
    box-shadow: 2px 2px 5px 2px #a7a7a785;
}

.cardBox {
    padding: 20px;
    cursor: pointer;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 5px 10px 35px 10px #a7a7a785;
}

.cardBox p {
    color: #363636 !important;
}

.skill {
    min-width: 180px;
    font-size: 16px;
    margin-right: 50px;
}

.form {
    padding: 0 42px;
}

.form .next {
    width: auto;
    border: none;
    float: right;
    color: #fff;
    border-radius: 20px;
    background-color: #3E3B4E;
    padding: 15px 50px 15px 50px;
    margin-bottom: 15px;
}

.form .prev {
    width: auto;
    border: none;
    float: right;
    color: #fff;
    border-radius: 20px;
    background-color: rgba(62, 59, 78, 0.7);
    padding: 15px 50px 15px 50px;
    margin-right: 10px;
}

.faArrowRight {
    color: #fff;
    margin: 0px 0px 2px 20px;
}

.faArrowLeft {
    color: #fff;
    margin-right: 15px;
}

.lang {
    font-size: 14px;
}

.span {
    color: black;
    text-align: left;
}

.card p {
    color: #3E3B4E;
}

.viewMore {
    color: #F6BA42;
}

.card {
    width: 100%;
    margin: 15px;
    color: #fff;
    padding: 20px;
    cursor: pointer;
    text-align: left;
    user-select: none;
    border-radius: 20px;
    background-color: #3E3B4E;
}

.cardII,
.cardIII {
    width: 100%;
    height: 50px;
    color: #fff;
    display: flex;
    cursor: pointer;
    text-align: left;
    user-select: none;
    padding: 10px 20px;
    margin-bottom: 15px;
    border-radius: 20px;
}

.cardII p,
.cardIII p {
    color: #fff !important;
}

.cardII {
    background-color: #1d1546;
}

.cardIII {
    background-color: #584c93;
}

p {
    color: #fff;
}

.link {
    background-color: white;
    float: right;
    color: #000;
    text-align: center;
    border-radius: 20px;
    border: none;
    font-size: 14px;
    padding: 5px 20px;
}

.links {
    text-decoration: none;
    color: #F6BA42;
}

.icon {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.left {
    text-align: left;
}

.left span {
    font-weight: bold;
    font-size: 15px;
}

.left label {
    margin-top: -25px;
    font-size: 13px;
}

.right {
    text-align: right;
}

.name1 {
    display: inline-block;
    background-color: #fff;
    color: black;
    padding: 5px 20px;
    font-size: 12px;
    margin: -10px 0px 30px 20px;
    border-radius: 20px;

}

.name {
    color: black;
    font-size: 12px;
    padding: 5px 20px;
    border-radius: 20px;
    display: inline-block;
    background-color: #fff;
    margin: -10px 0px 50px 50px;

}

.danger {
    border: none;
    color: white;
    font-size: 12px;
    border-radius: 100px;
    background-color: #F65042;
    padding: 10px 30px 10px 30px;
}

.sendCode {
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 100px;
    background-color: #3E3B4E;
    padding: 15px 100px 15px 100px;
}

.form input[type=checkbox] {
    width: 30px;
    border: none;
    height: 20px;
    outline: none;
    cursor: pointer;
    border-radius: 10px;
}

.cardHeader input[type=checkbox] {
    margin-bottom: 8px;
}

.form div button {
    width: 10%;
    font-size: 18px;
    border-radius: 50px;
}

.form1 {
    text-align: left;
    margin: 20px 50px;
}

.form1 input,
.form select,
.form textarea {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px;
    margin: 10px 0px 20px 0px;
    border-bottom: 1px solid #363636;
}

.form1 div button {
    width: 50%;
    font-size: 18px;
    border-radius: 50px;
}

.footer {
    padding: 0px;
    margin-top: 220px;
}

.loader {
    width: 80vw;
    height: 50vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.loader p {
    margin-top: 20px;
}

.cardText {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.addMoreButton {
    width: 100px;
    height: 50px;
    color: #fff;
    display: flex;
    outline: none;
    font-size: 14px;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    background-color: #645a91;
}

.addMoreButton span:nth-child(2) {
    font-size: 20px;
    margin-left: 5px;
}

.filters {
    display: flex;
    margin: 20px 0;
    align-items: center;
}

.filter {
    width: 30vw;
    margin-right: 30px;
}

.clear {
    margin-top: 20px;
}

.clear .label {
    margin-right: 10px;
}

.clear,
.clear .label {
    cursor: pointer;
}

.clear:hover,
.clear:hover .label {
    user-select: none;
    color: blueviolet;
}

.label {
    font-weight: bold;
}

.title {
    margin-top: 30px;
    font-size: 25px;
}

.skillsCompetenciesContainer {
    display: flex;
}

.row {
    overflow: scroll;
    width: 400px;
    height: 100%;
    margin: 0 30px;
    word-wrap: break-word;
}

.cardHeader {
    display: flex;
    align-items: center;
}

.noGames {
    display: flex;
    height: 350px;
    justify-content: center;
    align-items: center;
}

.errorMessage {
    color: red;
}

.card1 {
    background-color: #F4F4F4;
    width: 100%;
    margin: 25px 5px 25px 25px;
    padding: 30px 20px 40px 20px;
    border: solid 1px #D8D8D8;
    border-radius: 20px;
    text-align: left;
}

.card1 ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.card1 ul li {
    float: left;
    width: 50%;
    line-height: 1;
}

.card1 ul li h6 {
    color: grey;
}

.card1 table {
    width: 100%;
    margin-top: 20px;
}

.success {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: #27E34B;
    border: none;
    color: white;
    font-size: 12px;
}
