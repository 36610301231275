body {
    margin: 0px;
    padding: 0px;
    font-family: 'Roboto' sans-serif;
}

h1, h2, h3 {
    font-weight: bold;
}

.header {
    background-color: #3E3B4E;
    margin: 0px;
    padding: 20px 0px;

}

.header ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 30px 0px 5px 0px;
    overflow: hidden;
}

.header ul li {
    width: 100%;
    margin: 0px;
    padding: 14px 5px 0px 15px;
}

.header ul li.active {
    width: 100%;
    background-color: #E8DBC3;
    padding: 14px 5px 0px 15px;
    border-right: 5px solid #F6BA42;
}

.header ul li.active .menu {
    color: #3E3B4E;
}

.header ul li.active .menu svg path {
    fill: #3E3B4E;
}

.header ul li:hover .menu {
    color: #3E3B4E;
}

.header ul li:hover {
    width: 100%;
    background-color: #E8DBC3;
    padding: 14px 5px 0px 15px;
    border-right: 5px solid #F6BA42;
}

.header ul li:hover .menu {
    color: #3E3B4E;
}

.header ul li:hover .menu svg path {
    fill: #3E3B4E;
}

.menu {
    background-color: transparent;
    outline: none !important;
    text-decoration: none;
    color: white;
    border: none;
}

.lang {
    font-size: 14px;
}

.menu:focus, .menu:hover, .menu::after {
    outline: none !important;
    background-color: transparent;
    border: none;
}

.content {
    margin-left: 260px;
    width: 100%;
}


.topMenu {
    list-style-type: none;
    text-align: right;
    margin: 0px;
    height: 100px;
}

.user {
     border: 2px solid #3E3B4E;
     padding: 5px;
     margin-right: 20px;
     border-radius: 50%;
}

.topMenu hr {
    padding: 0px;
    margin: 10px 0px;
}

.logo {
    width: 50px;
    margin-left: 25px;
}

.report {
    background-color: #3E3B4E;
    margin: 30px;
    padding: 30px;
    border-radius: 20px;
    text-align: left;
}

.people {
    margin: -20px -20px 0px -20px;
    width: 100%;
}

.info {
    display: inline-block;
}

.info p {
    font-size: 25px;
     color: #F6BA42 !important;
}

.info p span {
     color: #FFF;
 }

 .primary {
     background-color: #fff;
     color: #FD0D1B;
     border: solid #FD0D1B 2px;
     border-radius: 10px;
     padding: 10px 30px;
     width: 150px;
 }

 .secondary {
    background-color: #fff;
    color: #FD7422;
    border: solid #FD7422 2px;
    border-radius: 10px;
    padding: 10px 30px;
    width: 150px;
 }

 .space {
     width: 200px;
 }

.card {
    background-color: #F2F2F2;
    margin: 30px;
    padding: 30px;
    border-radius: 20px;
    text-align: left;
    border: solid 1px #F6BA42;
    color: #3E3B4E !important;
}

.card p {
    color: #3E3B4E;
}

.viewMore {
    color: #F6BA42;
}

.card1 {
    background-color: #1DAEFC;
    width: 100%;
    text-align: left;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 10px 10px 0px 0px;
}

.card2 {
    background-color: #F65042;
    width: 100%;
    text-align: left;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 10px 10px 0px 0px;
}

.card3 {
    background-color: #F6BA42;
    width: 100%;
    text-align: left;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 10px 10px 0px 0px;
}

.card4 {
    background-color: #3E3B4E;
    width: 100%;
    text-align: left;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 10px 10px 0px 0px;
}

.card5 {
    background-color: #1EC47E;
    width: 100%;
    text-align: left;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 10px 10px 0px 0px;
}

.card6 {
    background-color: #B9499B;
    width: 100%;
    text-align: left;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 10px 10px 0px 0px;
}

p {
    color: #fff;
}

.link {
    background-color: white;
    width: 100%;
    text-align: center;
    border-radius: 50px;
    border: none;
    padding: 12px;
}

.icon {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.left {
    text-align: left;
    padding: 10px 0px;
}

.left span {
    font-weight: bold;
    font-size: 15px;
}

.left label {
    margin-top: -25px;
    font-size: 13px;
}

.right {
    text-align: left;
}

.skill {
    display: inline-block;
}

.name1 {
    display: inline-block;
    background-color: #fff;
    color: black;
    padding: 5px 20px;
    font-size: 12px;
    margin: -10px 0px 30px 20px;
    border-radius: 20px;

}

.name {
    display: inline-block;
    background-color: #fff;
    color: black;
    padding: 5px 20px;
    font-size: 12px;
    margin: -10px 0px 50px 50px;
    border-radius: 20px;

}

.danger {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: #F65042;
    border: none;
    color: white;
    font-size: 12px;
}

.success {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: #363636;
    border: none;
    color: white;
    font-size: 12px;
}

.share {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: #F6BA42;
    border: none;
    color: white;
    font-size: 12px;
}

.sendCode {
    padding: 15px 100px 15px 100px;
    border-radius: 100px;
    background-color: #3E3B4E;
    border: none;
    color: white;
    font-size: 16px;
}

.addskill {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: #F6BA42;
    border: none;
    color: white;
    font-size: 16px;
}

.addtalent {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: #3E3B4E;
    border: none;
    color: white;
    font-size: 16px;
}

.edit {
    padding: 5px 40px 5px 40px;
    border-radius: 100px;
    background-color: #3E3B4E;
    border: none;
    color: white;
    font-size: 14px;
}

.delete {
    padding: 5px 35px 5px 35px;
    border-radius: 100px;
    background-color: rgb(238, 67, 67);
    border: none;
    color: white;
    font-size: 14px;
}
.form {
    text-align: left;
    margin: 20px 50px;
}

.form input, .form select {
    margin: 10px 0px 20px 0px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #363636;
    border-radius: 0px;
}

.form div button {
    width: 50%;
    border-radius: 50px;
    font-size: 18px;
}


.footer {
    margin-top: 220px;
    padding: 0px;
}

thead td {
    font-weight: bold;
}

.list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.list li {
    float: left;
}

.box {
    height: 120px;
    width: 120px;
    padding: 20px 10px;
    border: #FD7422 solid 1px;
    border-radius: 5px;
}

.box1 {
    height: 120px;
    width: 120px;
    padding: 20px 10px;
    border: #FD0D1B solid 1px;
    border-radius: 5px;
}

.box0 {
    height: 120px;
    width: 120px;
    padding: 20px 10px;
    border: transparent solid 1px;
    border-radius: 5px;
}

.empty {
    height: 120px;
    width: 120px;
    padding: 10px;
    border-radius: 5px;
}

.card0 {
    background-color: #F2F2F2;
    width: 100%;
    padding: 20px;
    text-align: left;
    border-radius: 10px;
    color: #3E3B4E;
}

.card p {
    color: #3E3B4E;
}

.card0 p {
    padding: 30px;
    color: #3E3B4E;
}