body {
    margin: 0px;
    padding: 0px;
    font-family: Roboto;
}

h1, h2, h3 {
    font-weight: bold;
    color: #363636;
}

.subtitle {
    color: #3E3B4E;
    margin: 0px 0px 30px 0px;
}

p {
    color: gray;
    font-size: 14px;
}

.lang {
    color: gray;
    font-size: 14px;
}

label {
    color: gray;
    font-size: 16px;
}

.lang {
    padding: 30px;
    float: right;
}
.header {
    margin-bottom: -40px;
}
.content {
    text-align: center;
    padding-bottom: 100px;
    margin: 0% 15%;
}

.content1 {
    text-align: center;
    margin: 0% 15% -10px 15%;
}

.content2 {
    text-align: center;
    padding-bottom: 100px;
    margin: 0% 15%;
}

.form {
    text-align: left;
    margin-top: 20px;
}

.form input {
    width: 100%;
    margin: 0px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #363636;
    border-radius: 0px;
}

.form div button {
    width: 100%;
    border-radius: 50px;
    font-size: 16px;
}

.step {
  height: 15px;
  width: 15px;
  margin: 0;
  background-color: #FFF;
  border: 1px solid black;
  border-radius: 50%;
  display: inline-block;
}

.stepActive {
  height: 15px;
  width: 15px;
  margin: 0;
  background-color: #F6BA42;
  border: 1px solid black;
  border-radius: 50%;
  display: inline-block;
}

.content hr {
    display: inline-block;
    background-color: black;
    width: 50px;
    padding: 1px;
    margin-bottom: 7px;
}

.content1 hr {
    display: inline-block;
    background-color: black;
    width: 50px;
    padding: 1px;
    margin-bottom: 7px;
}

.content2 hr {
    display: inline-block;
    background-color: black;
    width: 50px;
    padding: 1px;
    margin-bottom: 7px;
}

.upload {
    border: #F6BA42 dashed 2px;
    border-radius: 20px;
    margin: 20px 40px;
    padding: 80px 50px;
    text-align: center;
}

.upload .camera {
    fill: #F6BA42;
    font-size: 50px;
    text-align: center;
}

.upload input[type=file] {
  margin-top: -50px;
  opacity: 0;
}

.upload input[type=file]::after {
  margin-top: -50px;
  opacity: 0;
}

.link {
    color: grey;
    text-decoration: none;
}

.link:hover {
    color: #f6ba42;
    text-decoration: none;
}

.card {
    background-color: #f6ba42;
    padding: 25px;
    height: 100%;
}

.generatedCode {
    border: #F6BA42 1px solid;
    border-radius: 20px;
    letter-spacing: 0.7cm;
    padding: 10px 20px 10px 40px;
    font-size: 50px;
}

.sendCode {
    padding: 15px 100px 15px 100px;
    border-radius: 100px;
    background-color: #3E3B4E;
    border: none;
    color: white;
    font-size: 16px;
}

.visible {
    margin-top: -50px;
    margin-right: 20px;
    font-size: 20px;
    float: right;
}
.right {
    height: 100%;
    text-align: center;
    background-color: #f6ba42;
}

.image1 {
    width: 60vw;
    margin-bottom: 10px;
}

.logo {
    width: 50px;
    margin: 20px 0 0 50px;
}

.logo1 {
    width: 100px;
    margin: 20px 0px;
}


.select {
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0px 50px 20px 50px;
}

.footer p {
    color: gray;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
}
.hidden {
    visibility: hidden
}