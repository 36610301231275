body {
    margin: 0px;
    padding: 0px;
    font-family: Roboto;
}

h1, h2, h3 {
    font-weight: bold;
}

.header {
    background-color: #3E3B4E;
    margin: 0px;
    padding: 20px 0px;

}

.header ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 30px 0px 5px 0px;
    overflow: hidden;
}

.header ul li {
    width: 100%;
    margin: 0px;
    padding: 14px 5px 0px 15px;
}

.header ul li.active {
    width: 100%;
    background-color: #E8DBC3;
    padding: 14px 5px 0px 15px;
    border-right: 5px solid #F6BA42;
}

.header ul li.active .menu {
    color: #3E3B4E;
}

.header ul li.active .menu svg path {
    fill: #3E3B4E;
}

.header ul li:hover .menu {
    color: #3E3B4E;
}

.header ul li:hover {
    width: 100%;
    background-color: #E8DBC3;
    padding: 14px 5px 0px 15px;
    border-right: 5px solid #F6BA42;
}

.header ul li:hover .menu {
    color: #3E3B4E;
}

.header ul li:hover .menu svg path {
    fill: #3E3B4E;
}

.menu {
    text-decoration: none;
    color: white;
}

.content {
    margin-left: 260px;
    width: 100%;
}


.topMenu {
    list-style-type: none;
    text-align: right;
    margin: 0px;
    height: 100px;
}

.user {
     border: 2px solid #3E3B4E;
     padding: 5px;
     margin-right: 20px;
     border-radius: 50%;
}

.topMenu hr {
    padding: 0px;
    margin: 10px 0px;
}

.company {
    font-size: 100px;
    border-radius: 50%;
    padding: 5px;
    background-color: #fff;
}

.logo {
    width: 50px;
    margin-left: 25px;
}

.title {

}

.card {
    background-color: #F4F4F4;
    text-align: center;
    padding: 50px;
    border: #D8D8D8 1px solid;
    border-radius: 20px;
}

.card1 {
    background-color: #F4F4F4;
    text-align: left;
    padding: 30px 0px;
    border: #D8D8D8 1px solid;
    border-radius: 20px;
}

.card1 hr {
    padding: 0.5px;
    margin: 0px;
}

.card1 ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.card1 ul li {
    display: inline;
    margin: 0px 40px;
}

.card1 ul li button {
    display: inline;
    font-weight: bold;
    text-align: left;
    text-decoration: none;
    margin: 0px 10px;
    padding-bottom: 23px;
    border: none;
    background-color: transparent;
}

.card1 ul li .active {
    display: inline;
    font-weight: bold;
    text-align: left;
    text-decoration: none;
    margin: 0px 10px;
    padding-bottom: 18px;
    border: none;
    background-color: transparent;
    border-bottom: 5px #F6BA42 solid;
}

.addBill {
    background-color: #fff;
    color: #F6BA42;
    padding: 10px 20px;
    float: right;
    border: 1px #F6BA42 solid;
    border-radius: 20px;
    font-size: 13px !important;
}

.sendCode {
    padding: 15px 100px 15px 100px;
    border-radius: 100px;
    background-color: #3E3B4E;
    border: none;
    color: white;
    font-size: 16px;
}

.form1 div button {
    width: 50%;
    border-radius: 50px;
    font-size: 18px;
}

.form1 {
    text-align: left;
    margin: 20px 50px;
}

.form1 input, .form1 select, .form1 textarea, .input {
    width: 100%;
    margin: 10px 0px 20px 0px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #363636;
    border-radius: 0px;
    background-color: transparent;
}

.form1 input:focus, .form1 select:focus, .form1 textarea:focus, .input:focus {
    width: 100%;
    margin: 10px 0px 20px 0px;
    outline: none !important;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #363636;
    border-radius: 0px;
    background-color: transparent;
}

.form1 div button {
    width: 50%;
    border-radius: 50px;
    font-size: 18px;
}


.footer {
    margin-top: 220px;
    padding: 0px;
}

.inputFile::-webkit-file-upload-button { 
    visibility: hidden;
}

.inputFile::before {
    content: 'Select some files';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
}