.container {
    display: flex;
    user-select: none;
    flex-direction: column;
}
.label {
    text-align: left;
}
.error {
    margin: 5px 0;
    text-align: end;
    font-size: 12px;
    font-weight: 600;
    color: #ff0000;
}
.field,
.field:focus {
    border: none;
    width: 100%;
    height: 40px;
    outline: none;
    transition: 0.1s;
    padding: 9px 16px;
    border-radius: 3px;
    box-shadow: 0 0 0 0.5pt rgba(0,0,0,0.3);
}
.secureField,
.secureField:focus {
    border: none;
    width: 330px;
    height: 40px;
    outline: none;
    transition: 0.1s;
    padding: 9px 16px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0 0 0 1pt transparent;
}