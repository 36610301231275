.body {
    overflow: hidden;
}

h1, h2, h3 {
    font-weight: bold;
    color: #363636;
}

.subtitle {
    color: #3E3B4E;
    margin: 0px 0px 30px 0px;
}

p {
    color: gray;
    font-size: 14px;
}

.lang {
    color: gray;
    font-size: 14px;
}

label {
    color: gray;
    font-size: 16px;
}

header ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

header ul li {
    float: left;
    text-align: left;
    width: 50%;
}

.lang {
    padding: 30px;
    float: right;
}

header {
    height: 20%;
}

.content1 {
    margin: 10% 10% ;
    height: 50%;
}

.content2 {
    margin: 3% 15% 5% 15%;
    height: 55%;
}

.content {
    margin: 0% 15% ;
    height: 50%;
}

.form {
    text-align: left;
}

.form input {
    width: 100%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0px;
    border-bottom: 1px solid #363636;
}

.form div button {
    width: 100%;
    border-radius: 50px;
    font-size: 18px;
}

.form1 {
    text-align: center;
    margin: 30px;
}

.form1 input, .input {
    width: 100%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0px;
    border-bottom: 1px solid #363636;
}

.form1 input:focus, .input:focus {
    width: 100%;
    outline: none;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0px;
    border-bottom: 1px solid #363636;
}

.form1 div button {
    width: 100%;
    border-radius: 50px;
    font-size: 18px;
}

.link {
    color: grey;
    text-decoration: none;
}

.link:hover {
    color: #f6ba42;
    text-decoration: none;
}

.card {
    background-color: #f6ba42;
    padding: 25px;
    height: 100%;
}

.right {
    height: 100%;
    text-align: center;
    background-color: #f6ba42;
}

.image1 {
    width: 90%;
}

.logo {
    width: 50px;
    margin: 20px 0 0 50px;
}

.logo1 {
    width: 100px;
    margin: 50px 0px;
}


.select {
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0px 50px 20px 50px;
}

.footer {
    margin: 20% 0%;
}

.footer p {
    text-align: center;
    color: gray;
    font-size: 14px;
}

.error {
    color: red;
    font-size: 12px;
    text-align: left;
}

.sendCode {
    padding: 15px 100px 15px 100px;
    border-radius: 100px;
    width: 100%;
    background-color: #3E3B4E;
    border: none;
    color: white;
    font-size: 16px;
}